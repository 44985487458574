/* Most styles moved to tailwind classes */
.Profile ul li {
  @apply text-md;

  & + li {
    @apply mt-2;
  }
}

.Profile .spinner {
  @keyframes radar {
    0% {
      outline-offset: 0;
      opacity: 0.75;
    }
    80% {
      outline-offset: 0.75rem;
      opacity: 0;
    }
    100% {
      outline-offset: 0.75rem;
      opacity: 0;
    }
  }

  @apply h-2 w-2 block rounded-full outline-dark transition-all;
  animation: infinite radar 1s;
}

.Profile .content ul li::before {
  content: '\2022';
  @apply mr-2;
}

.Profile a[name='contact'] {
  @apply inline-block pl-14 bg-no-repeat;
  background-image: url('../../assets/email-icon.png');
  background-position-y: center;
  background-position-x: 1.5rem;
  background-size: 1.5rem auto;
}

.Profile .price {
  @apply flex justify-around;

  span {
    @apply inline-block bg-no-repeat bg-center h-6 w-3;
    background-size: 0.75rem 1.25rem;
    background-image: url('../../assets/dollar.svg');
  }

  @for $n from 1 through 4 {
    &--#{$n} {
      span:nth-child(-n + #{$n}) {
        background-image: url('../../assets/dollar-dark.svg');
      }
    }
  }

  &:hover {
    & + .tooltip {
      @apply absolute inline-block top-full left-1/2 transform -translate-x-1/2 w-40 translate-y-1;
    }
  }
}

.Profile .pill {
  @apply uppercase inline-block my-1 text-xs py-px px-2 rounded-btn bg-gradient-to-r font-bold from-goldLight to-gold text-white;

  &:hover {
    .tooltip {
      @apply absolute inline-block top-full left-1/2 lg:left-0 transform w-60 -translate-x-1/2 lg:translate-x-0 translate-y-1 z-10;
    }
  }
}

.Arrow {
  @apply inline-flex items-center justify-center w-8 h-8 mx-1 border border-black border-solid rounded-full cursor-pointer;

  &:hover {
    @apply bg-black;

    span {
      @apply border-white;
    }
  }

  span {
    @apply relative inline-block w-2 h-2 transform text-transparent;
  }

  &--left {
    span {
      @apply -rotate-45 border-t-2 border-b-0 border-l-2 border-r-0 border-black border-solid -right-px;
    }
  }

  &--right {
    span {
      @apply rotate-45 border-t-2 border-b-0 border-l-0 border-r-2 border-black border-solid -left-px;
    }
  }
}
