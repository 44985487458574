#Search #profiles {
  margin-bottom: 0;
}

.Search__no-more {
  padding: 60px 0 20px;
  text-align: center;
  color: #999;
  font-style: italic;
}

.Search__separator {
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
  background: #f1f1f4;
  padding: 60px 0 40px;
  width: 100%;
  position: relative;
  color: $black;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    background: #f1f1f4;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    z-index: 1;
  }

  span {
    font-weight: 800;
    font-size: 1.5rem;
    z-index: 2;
  }
}

.Cards {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 -0.75rem;

  &.Cards--more {
    padding-top: 30px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      background: #f1f1f4;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      z-index: 1;
    }
  }
}

.Card {
  background: #ffffff;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  text-decoration: none;
  color: $secondary-text;
  width: 100%;
  transition: 0.3s all cubic-bezier(0.17, 0.67, 0, 1.35);
  z-index: 2;
  height: 100%;

  &__location,
  &__certified {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  &__certified {
    text-transform: uppercase;
    font-weight: bold;
    color: $gold;
  }

  &__copy {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 40px 30px;
    text-align: left;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  &__select-coach {
    text-align: center;
    margin-bottom: 1rem;
    z-index: 200;
  }

  &__see-profile,
  &__more-coaches {
    text-align: center;
    margin-bottom: 1.5rem;
    z-index: 1000;

    button {
      background: transparent;
      border: none;
      color: $black;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }

      &:focus {
        outline: none;
      }
    }
  }

  &--overlay {
    opacity: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    transition: 0.3s all cubic-bezier(0.17, 0.67, 0, 1.35);
  }

  &--wrapper {
    display: flex;
    justify-content: stretch;
    position: relative;
    z-index: 2;
    padding: 0.4rem;
    width: 100%;
    transition: 0.3s all cubic-bezier(0.17, 0.67, 0, 1.35);

    .highlight {
      width: 100%;
      padding: 7px;
      background: transparent;
      transition: 0.8s all cubic-bezier(0.17, 0.67, 0, 1.35);
      border-radius: 18px;

      &--selected {
        background: linear-gradient(235deg, #e7c03a 22.18%, #fce39f 29.46%, #e7c03a 36.39%, #ad8200 57.7%);
        padding: 7px; //this?

        .Card--overlay {
          display: block;
          opacity: 1;
          border-radius: 12px;

          &__inner {
            width: 100%;
            height: 100%;
            background-image: url(../../assets/BigGold-Check.png);
            background-repeat: no-repeat;
            background-size: 40%;
            background-position: center center;
            z-index: 1000;

            &.hovered {
              background-image: url(../../assets/BigGold-X.png);
            }
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      &.in-profile-modal {
        width: 100%;
      }
    }

    @include respond-to('768px') {
      width: 50%;
    }

    @include respond-to('desktop') {
      width: 33.33%;
      &.in-profile-modal {
        width: 50%;
      }
    }

    &:hover {
      transform: scale(1.025);
    }
  }

  &--end-of-results {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem;

    &__empty {
      width: 100%;
    }
  }

  &__cover {
    background-position: center;
    background-size: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    height: 180px;
    width: 100%;
  }
  &__avatar {
    background-position: center center;
    background-color: #faf9fc;
    background-size: cover;
    border-radius: 50%;
    height: 110px;
    margin: -55px auto 20px auto;
    width: 110px;
  }
  &__title {
    font-size: 22px;
    font-weight: bold;
    line-height: 31px;
    text-align: center;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  &__copy {
    flex: 1;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 20px 40px 30px;
    text-align: left;
    overflow: hidden;
    overflow-wrap: break-word;
  }

  .Card-meta {
    display: flex;
    justify-content: space-between;
    margin-top: -75px;

    &__certs,
    &__price {
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 15px 0;
      position: relative;
      max-width: calc(50% - 55px);
      min-height: 48px;
    }

    &__certs {
      flex-direction: column;
    }

    &__price {
      &:hover {
        .tooltip {
          display: inline-block;
          left: 50%;
          text-align: center;
          transform: translateX(-50%);
          top: 60px;
        }
      }
    }

    &__certs .Card__pill {
      align-self: center;
      color: white;
      background: linear-gradient(
        225deg,
        rgba(231, 192, 58, 1) 0%,
        rgba(252, 227, 159, 1) 20%,
        rgba(231, 192, 58, 1) 40%,
        rgba(173, 130, 0, 1) 100%
      );
      border-radius: 20px;
      padding: 2px 8px;
      margin-bottom: 0.5rem;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.625rem;

      &.agency,
      &.workshop {
        &:hover {
          .tooltip {
            display: inline-block;
            left: 50%;
            text-align: center;
            transform: translateX(-50%);
            top: 60px;

            &.large {
              width: 300px;
              left: -10%;
              transform: translateX(0%);
              text-align: left;
            }
          }
        }
      }
    }
  }

  .Card__title {
    margin-top: 15px;
  }
  .Card__distance {
    color: #f5a623;
    text-transform: uppercase;
    padding: 15px 15px 0;
    justify-content: center;
    display: flex;
    align-items: center;
    font-weight: bold;

    .Card__pin-icon {
      background-image: url(../../assets/pin.svg);
      display: inline-block;
      height: 16px;
      margin: 0 6px;
      width: 13px;
    }
  }
  .Card-footer__link {
    border-radius: 0 0 6px 6px;
    background: white;
    border: 0;
    border-top: 1px #d4d6e5 solid;
    color: #33c7ff;
    display: block;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    letter-spacing: 4px;
    padding: 25px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 100%;
    appearance: none;
    &:hover {
      transition: all 0.3s;
      background: #33c7ff;
      color: #fff;
    }
  }
}

.Card-meta__price .Icon,
.MockSelect__placeholder .Icon {
  background-image: url(../../assets/dollar.svg);
  display: inline-block;
  height: 18px;
  margin: 0 6px;
  width: 10px;
  &--dark {
    background-image: url(../../assets/dollar-dark.svg);
  }
}

.coach-form {
  flex-direction: column;
}

.MockSelect__placeholder .Icon {
  margin: 0 3px;
  position: relative;
  top: 4px;
}

.SearchBar {
  align-items: center;
  display: flex;
  flex-direction: column;
  @include respond-to('desktop') {
    flex-direction: row;
  }
}

.loading {
  text-align: center;
  .lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $black;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.FiltersHeader {
  padding-top: 4%;
  margin-bottom: 0 !important;

  &.name-only {
    max-width: 600px;
    padding-bottom: 4% !important;

    @media only screen and (max-width: 480px) {
      width: 100%;
    }
  }

  & + #profiles {
    transition: 0.25s;
    pointer-events: auto;
  }

  &.active {
    & + #profiles {
      opacity: 0.35;
      pointer-events: none;
    }
  }

  &--form {
    position: relative;
    max-width: 1250px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 25px;
    border: 1px solid rgba(#ddddde, 0.75);
    display: flex;
    flex-direction: column;

    @include respond-to(1024px) {
      border-radius: 50px;
      flex-direction: row;
      padding: 0;
    }

    .Form--field {
      position: relative;
      border-radius: 25px;
      padding: 1rem 1.5rem;
      transition: 0.25s;

      @include respond-to(1024px) {
        position: revert;
        border-radius: 999px;
        padding: 1rem 2.5rem;
      }

      &:hover {
        background-color: rgba(#ddddde, 0.25);
      }

      &.active {
        background-color: white;

        @include respond-to(1024px) {
          box-shadow: 0 0 10px rgba(#848484, 0.15);
        }

        .Form--modal {
          opacity: 1;
          pointer-events: auto;
          top: calc(100% + 0.5rem);

          @include respond-to(1024px) {
            top: calc(100% + 1rem);
          }
        }

        .Button--clear {
          display: inline;
          opacity: 1;
          pointer-events: auto;
        }
      }

      .Button--clear {
        top: 50%;
        left: 100%;
        transform: translate(-100%, -50%);

        @include respond-to(1024px) {
          left: 100%;
          transform: translate(50%, -50%);
        }
      }

      label {
        font-weight: bold;
        // text-transform: uppercase;
        font-size: 0.85rem;
      }

      input {
        border: none;
        background-color: transparent;
        margin-top: auto;
        padding: 0.25rem 0;
        outline: none;
      }

      @include respond-to(1024px) {
        & + .Form--field {
          .Form--stack {
            &:before {
              content: '';
              height: 75%;
              width: 1px;
              background-color: #cecece;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(-2.5rem, -50%);
            }
          }
        }

        &.active,
        &:hover {
          .Form--stack {
            &:before {
              opacity: 0;
            }
          }

          & + .Form--field {
            .Form--stack {
              &:before {
                opacity: 0;
              }
            }
          }
        }
      }
    }

    .Form--stack {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;

      & > *:first-child {
        margin-bottom: 0.25rem;
      }
    }

    .Form--modal {
      top: calc(100% + 2rem);
      left: 0;
      opacity: 0;
      pointer-events: none;
      transition: 0.25s;
    }

    .Form--submit {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      @include respond-to(1024px) {
        margin-top: 0;
        padding: 0.25rem 0.55rem 0.25rem 0.55rem;
      }

      .Button {
        position: relative;
        height: 3.5rem;
        border-radius: 50px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include respond-to(1024px) {
          height: auto;
          padding-bottom: calc(100% - 1rem);
          width: 3rem;

          span {
            display: none;
          }
        }

        .Icon {
          margin-right: 1rem;

          @include respond-to(1024px) {
            margin-right: 0;
            @include center;
          }
        }
      }
    }
  }
}

.FormFilters {
  height: 100%;
}

.FiltersModal,
#Guided-Search {
  font-family: 'Montserrat', sans;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 500;
  position: absolute;
  z-index: 3;
  top: calc(100% + 1rem);
  left: 0;
  width: 100%;
  padding: 0rem;
  background-color: white;
  border-radius: 25px;
  box-shadow: 0 2px 6px rgba(#848484, 0.15);

  @include respond-to('tablet') {
    padding: 2.5rem;
  }

  @include respond-to(1024px) {
    padding: 3rem 4rem;
    border-radius: 40px;
  }

  .FiltersModalContent,
  .Question-box {
    h3 {
      font-size: 0.85rem;
      font-weight: 800;
    }

    h3.subTitle {
      font-size: 0.85rem;
      font-weight: 800;
    }

    .Filter {
      & + .Filter {
        margin-top: 1.5rem;
      }
    }

    .ratings-wrapper {
      justify-content: flex-start;
      margin-top: 20px;
    }

    .SkillsGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 40px;
    }

    .SkillsFilter {
      margin-bottom: 25px;
      column-gap: 40px;
      position: relative;

      @media screen and (min-width: 550px) {
        columns: 3;
      }

      &.one-column {
        columns: 1;
      }
      &.two-columns {
        columns: 1;
        @include respond-to('tablet') {
          columns: 2;
        }
      }
      &.three-columns {
        columns: 3;
      }
    }

    .SkillsFilterCheckbox {
      position: relative;
      display: flex;
      align-items: center;
      padding: 6px 0;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid-column;
      display: table;

      .location {
        display: none;
        align-items: center;
        margin-left: 2.5rem;
        margin-top: 0.75rem;

        &--show {
          display: flex;
        }

        span.required {
          position: relative;
          top: -1rem;
          color: #f84769;
        }

        input {
          left: 0;
          min-width: 200px;
          border: 2px solid $black;
          position: relative;
        }

        .LocationInput-tag-city {
          margin-left: 2rem;
        }
      }

      input {
        position: absolute;
        left: -99999px;

        &:checked + label:before {
          background-color: $black;
          border: 1px solid $black;
          background-image: url(../../assets/white_check.svg);
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 65%;
        }
      }
      label {
        padding-left: 30px;
        position: relative;
        font-weight: 400;
        font-size: 1rem;
        display: block;
        cursor: pointer;
        color: #555;

        &.label-bold {
          font-weight: bold;
          padding-left: 40px;
        }

        &.label-black {
          color: $black;
        }

        &:before {
          content: '';
          height: 20px;
          width: 20px;
          border: 1px solid #d8d8d8;
          display: inline-block;
          border-radius: 4px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
        }

        &.align-top {
          &:before {
            top: 0px;
            transform: translateY(0);
          }
        }

        &:hover {
          color: $black;

          &:before {
            border-color: $black;
          }
        }

        &.label-disabled {
          color: grey;

          &:hover {
            cursor: default;
            &:before {
              border: 1px solid #d8d8d8;
            }
          }
        }
      }
    }

    .results {
      display: flex;
      justify-content: space-between;
      margin: 1.5rem 0;

      .next-results {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        border: none;

        span {
          color: $black;
          font-family: 'Montserrat';
          margin-right: 0.5rem;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: default;
          span {
            color: grey;
          }
        }

        &:focus {
          outline: none;
          transform: scale(1.05);
        }
      }
    }
  }
}

$unchecked-dollar: url(../../assets/dollar.svg);
$checked-dollar: url(../../assets/dollar-dark.svg);

.ratings-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}
.ratings {
  /* remove inline-block whitespace */
  font-size: 0;
  * {
    font-size: 1rem;
  }
  /* flip the order so we can use the + and ~ combinators */
  unicode-bidi: bidi-override;
  direction: rtl;
  & > input {
    opacity: 0;
    left: -9999px;
    position: absolute;
    & + label {
      /* only enough room for the star */
      display: inline-block;
      line-height: 0px;
      margin-right: 20px;
      overflow: hidden;
      text-indent: 9999px;
      width: 1em;
      white-space: nowrap;
      cursor: pointer;
      &:before {
        display: inline-block;
        text-indent: -9999px;
      }
    }
    &:checked ~ label:before,
    & + label:hover ~ label:before,
    & + label:hover:before {
      border: none !important;
    }
  }

  &--dollar {
    & > input {
      & + label {
        overflow: visible;
        position: relative;
        &:before {
          content: $unchecked-dollar;
        }
      }
      &:checked ~ label:before,
      & + label:hover ~ label:before,
      & + label:hover:before {
        content: $checked-dollar;
      }
      & + label:hover .tooltip {
        display: inline-block;
      }
      &:hover > input + label:before {
        content: $unchecked-dollar;
      }
      &:hover > input + label:hover ~ label:before,
      &:hover > input + label:hover:before {
        content: $checked-dollar;
      }
    }
  }
}

.tooltip {
  background: $secondary-text;
  border-radius: 2px;
  box-shadow: 0px 1px 5px #c3d0d8;
  color: #fff;
  display: none;
  font-family: 'Montserrat';
  font-size: 12px;
  font-weight: 500;
  left: 0px;
  height: auto;
  letter-spacing: 1px;
  line-height: 20px;
  padding: 7px 10px;
  position: absolute;
  text-indent: 0;
  text-transform: none;
  top: 30px;
}

#price_all {
  display: none;
  &:checked ~ label {
    color: black;
  }
}

label[for='price_all'] {
  color: #ddddde;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 17px;
  margin-left: 2px;
  margin-top: 2px;
}

//

.zero-count {
  background: #efeef1;
  border-radius: 16px;
  color: #212223;
  margin: 0 auto 60px auto;
  max-width: 650px;
  padding: 55px;
  text-align: center;
  &__title {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 10px;
    text-transform: uppercase;
    .pop {
      font-weight: 700;
    }
  }
  &__copy {
    font-weight: 400;
    font-size: 20px;
    font-style: italic;
  }
}

.zero-count-browse {
  margin: 30px 0 20px;
  text-align: center;
  @include respond-to('desktop') {
    margin-bottom: 0px;
  }

  &:before {
    background-color: #dddddd;
    content: '';
    display: block;
    height: 1px;
    margin-bottom: -12px;
    width: 100%;
  }
  &__title {
    background: #faf9fc;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 0 20px;
    text-transform: uppercase;
  }
}

#Guided-Search {
  position: relative;
  background: white;
  min-height: 100vh;
  padding-top: 0;
  border-radius: 0;

  h1 {
    font-size: 1.25rem;

    @include respond-to('tablet') {
      font-size: 2rem;
    }
  }

  &.already-used {
    .Guided-search-inner {
      text-align: center;
    }
  }

  .Guided-search-inner {
    width: 96%;
    @include respond-to('tablet') {
      max-width: 1400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.progress-bar-wrapper {
  width: 100%;
  margin-bottom: 2.5rem;

  .bar-grey {
    width: 100%;
    height: 4px;
    position: relative;
    background: #e8e6eb;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bar-gold {
    width: 0;
    height: 4px;
    background: #d4b165;
    transition: all 0.3s;
    position: absolute;
  }

  .progress-dot {
    height: 19px;
    width: 19px;
    background: #e8e6eb;
    border-radius: 50%;
    border: none;

    &:focus {
      outline: none;
      transform: scale(1.02);
    }

    &.dot-gold {
      background: #d4b165;
    }
    &.dot-complete {
      background-image: url(../../assets/white_check.svg);
      background-repeat: no-repeat;
      background-size: 60%;
      background-position: 52% 52%;
      z-index: 100;
    }
  }
}

.Question-box,
.Send-to-coaches {
  width: 100%;
  background: #faf9fc;
  border-radius: 18px;
  padding: 1rem 1rem;
  color: $black;

  @include respond-to('tablet') {
    padding: 2rem 2rem;
  }

  @include respond-to('desktop') {
    padding: 3rem 5rem;
  }

  h1 {
    margin: 0.5rem 0 1rem;
  }

  .step-counter {
    margin-bottom: 0;
    font-size: 1rem;
  }

  .button-wrapper {
    position: fixed;
    width: calc(100% - 2rem - 4%);
    bottom: 20px;
    margin-top: 3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 300;

    .Button {
      box-shadow: 0 2px 6px rgba(#848484, 0.15);
    }

    .disclaimer {
      display: none;
      position: absolute;
      transform: translate(-50%, 0%);
      left: 50%;
      top: 130%;
      font-size: 1rem;
      text-align: center;
      transition: all 0.2s;

      @include respond-to('tablet') {
        display: block;
        opacity: 0;
        &.show {
          opacity: 1;
        }
      }
    }

    .selected-count {
      position: absolute;
      text-align: left;
      right: 0rem;
      background: linear-gradient(235deg, #e7c03a 22.18%, #fce39f 29.46%, #e7c03a 36.39%, #ad8200 57.7%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      color: white;
      padding: 0.75rem 6rem 0.75rem 1rem;
      font-size: 1.25rem;
      font-weight: bold;
      text-transform: none;
      z-index: -1;

      &.no-count {
        background: white;
        color: $gold;
        pointer-events: none;
      }

      span {
        display: none;
        @include respond-to('tablet') {
          display: inline;
        }
      }

      &:hover {
        transform: none;
      }

      &:disabled {
        cursor: default;
      }
    }

    @include respond-to('tablet') {
      position: relative;
      width: 100%;
    }
  }
  // Overwriting some of the styles above
  .SkillsFilterCheckbox {
    color: $black;
    label {
      margin: 0.25rem 0;
    }
  }

  .option-description {
    font-weight: 400;
    font-size: 1rem;
    display: none;

    @include respond-to('tablet') {
      display: block;
    }
  }
}

.Guided-search-inner {
  margin-bottom: 6rem !important;
  @include respond-to('tablet') {
    margin-bottom: 0;
  }
}
