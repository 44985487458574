body {
  overflow-x: hidden;
}

.ctaButton[role='button'] {
  position: relative;
  display: inline-block;
  margin-bottom: 1.5rem;
  color: $blue-dark;
  font-size: 19px;
  font-weight: 700;
  letter-spacing: 3px;
  line-height: 20px;
  text-decoration: none;
  text-transform: uppercase;
  border-bottom: 1px solid $blue-dark;
  &:after {
    content: ' →';
    transition: 0.3s all;
    line-height: 20px;
    position: absolute;
    right: -40px;
  }
  &:hover:after {
    right: -50px;
  }
}

a.largeBtn[role='button'] {
  width: 100%;
  display: block;
  cursor: pointer;
  margin-top: 1.5rem;
  background: $black-light;
  border: 0px solid;
  border-radius: 3px;
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.4;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  text-decoration: none;
  transition: 0.3s all;
  text-transform: uppercase;
  border-radius: 50px;
  &:hover {
    background: $black;
  }
  @media screen and (min-width: 25rem) {
    max-width: 228px;
    margin-left: auto;
    margin-right: auto;
  }
}

a.quietBtn[role='button'] {
  width: 100%;
  display: block;
  cursor: pointer;
  margin-top: 1.5rem;
  background: transparent;
  border: 2px solid $secondary-text;
  border-radius: 50px;
  color: $secondary-text;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  transition: 0.3s all;
  &:hover {
    background: $secondary-text;
    color: #fff;
  }
  @media screen and (min-width: 25rem) {
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
  }
}

p {
  color: $paragraph-text;
  font-size: 1rem;
  line-height: 1.3;
  letter-spacing: 1px;
  margin-bottom: 1rem;
  @media screen and (min-width: 25rem) {
    font-size: 1.125rem;
  }
  @media screen and (min-width: 64rem) {
    font-size: 1.25rem;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h2 {
  color: $header-text;
  font-size: 1.8rem;
  line-height: 44px;
  text-align: center;
  margin-bottom: 1rem;
  @media screen and (min-width: 72rem) {
    font-size: 2.25rem;
  }
}

h3 {
  color: $header-text;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}

h3.sub {
  font-size: 1.19rem;
  font-weight: 400;
  font-style: italic;
}

h4 {
  color: $header-text;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 0.5rem;
}

/* general form styling */
section.splash {
  padding-top: 12%;
  padding-bottom: 12%;
  position: relative;

  .splash__content,
  .splash__image {
    z-index: 2;
  }

  h1 {
    font-size: 2.25rem;
    font-weight: 800;
    line-height: 3.5rem;
    letter-spacing: 1px;
    color: $black;
  }
  h5.tagline {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
    font-style: italic;
    color: $black;
  }

  @media screen and (min-width: 40rem) {
    padding: 0;
    h1 {
      margin-top: 2rem;
    }
  }

  @media screen and (min-width: 48rem) {
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 63%;
      height: calc(100% + 200px);
      z-index: 0;
      background-color: #faf9fc;
    }
    .pw {
      display: flex;
      padding: 50px 0 70px;
    }
    .splash__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-right: 50px;
      width: 50%;
      min-height: 600px;
    }

    .splash__image {
      background-image: url(../../assets/teamwork.jpg);
      background-size: cover;
      background-position: center center;
      position: absolute;
      top: 20px;
      right: 0;
      width: 50%;
      height: calc(100% - 120px);
    }
  }

  @media screen and (min-width: 64rem) {
    background-position: 130% -15%;
    h1 {
      font-size: 2.5rem;
      max-width: 580px;
    }
    h5.tagline {
      max-width: 450px;
      margin-bottom: 1.5rem;
      font-size: 1.625rem;
    }
  }

  @media screen and (min-width: 84rem) {
    background-size: 67%;
    background-position: 95% 0;
    div.pw {
      padding-top: 3%;
      margin-bottom: 66px;
    }
    a[role='button'] {
      margin-bottom: 3rem;
    }
    input,
    select {
      font-size: 1rem !important;
    }
  }

  @media screen and (min-width: 96rem) {
    background-size: 60%;
  }
}

/* section.splash */

section.values {
  border-top: 1px solid #d8d8d8;
  position: relative;
  z-index: 2;
  div[data-st='st-three'] {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 2rem;
    margin-bottom: 0;
    background: #fff;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
    position: relative;
    transition: 0.4s box-shadow;
    background: url(../../assets/Pencils.svg) no-repeat center left;
    background-size: 50px;
    background-position-x: 2rem;

    a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
    }
    strong {
      width: auto;
      clear: both;
      display: block;
      padding-left: 3.75rem;
      font-size: 1rem;
      line-height: 1.4;
      text-align: left;
    }
    &:first-child {
      background-image: url(../../assets/Computer.svg);
    }
    &:nth-child(3) {
      background-image: url(../../assets/FullSuite.svg);
    }
    &:nth-child(4) {
      background-image: url(../../assets/Email.svg);
    }

    &:hover {
      box-shadow: 0 2px 11px rgba(195, 208, 216, 0.8);
      z-index: 3;
    }
  }

  @media screen and (min-width: 25rem) {
    div[data-st='st-three'] {
      background-size: 60px;
      strong {
        padding-left: 5rem;
        font-size: 1.125rem;
      }
    }
  }

  @media screen and (min-width: 40rem) {
    margin-bottom: 0;
    div[data-st='st-three'] {
      width: 50%;
      padding: 1rem;
      strong {
        letter-spacing: 1px;
      }
    }
  }

  @media screen and (min-width: 48rem) {
    display: flex;
    flex-wrap: wrap;
    div[data-st='st-three'] {
      margin-right: 0;
    }
  }

  @media screen and (min-width: 64rem) {
    background: #fff;
    border-bottom: 1px solid #d8d8d8;
    align-content: stretch;
    align-items: stretch;
    justify-content: center;
    div[data-st='st-three'] {
      width: auto;
      padding: 1rem 2rem 1rem 1rem;
      float: none;
      border-bottom: 0px !important;
      border-right: 1px solid #d8d8d8;
      &:last-child {
        border-right: 0;
      }
    }
  }

  @media screen and (min-width: 72rem) {
    flex-wrap: nowrap;
    div[data-st='st-three'] {
      padding: 1.5rem 3rem 1.5rem 2.5rem;
      width: 30%;
      &:nth-child(3) {
        width: 45%;
      }
    }
  }

  @media screen and (min-width: 84rem) {
    div[data-st='st-three'] {
      padding: 2.25rem 3rem 2.25rem 2.5rem;
      strong {
        font-size: 1.15rem;
      }
      &:nth-child(3) {
        width: 35%;
      }
    }
  }
}

section.growth {
  position: relative;
  z-index: 2;
  .pw:nth-child(2) {
    flex-wrap: wrap;
    align-items: stretch;
    align-content: stretch;
    max-width: 1100px;
  }
  div.card {
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1.5rem;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 11px rgba(195, 208, 216, 0.5);
    transition: 0.3s all cubic-bezier(0.17, 0.67, 0, 1.35);
    position: relative;
    padding-bottom: 70px;
    h3 {
      padding-top: 75px;
      background: url(../../assets/Pencils.svg) no-repeat top left;
      background-size: auto 70px;
    }
    p {
      font-weight: 400;
    }
    a[role='button'] {
      margin-bottom: 0;
      border-bottom: 0;
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: none;
      color: #000;
      &:after {
        right: -25px;
      }
      &:hover:after {
        right: -35px;
      }
      position: absolute;
      bottom: 36px;
      left: 24px;
    }

    &:nth-child(2) h3 {
      background-image: url(../../assets/SalesFunnels.svg);
    }
    &:nth-child(3) h3 {
      background-image: url(../../assets/Computer.svg);
    }
    &:nth-child(4) h3 {
      background-image: url(../../assets/Marketing.svg);
    }
    &:nth-child(5) h3 {
      background-image: url(../../assets/Email.svg);
    }
    &:nth-child(6) h3 {
      background-image: url(../../assets/FullSuite.svg);
    }
  }

  @media screen and (min-width: 40rem) {
    background: #fff url(../../assets/gradient-growth.png) no-repeat center right;
    background-size: contain;
    div.pw:first-child {
      h2 {
        margin: 1rem auto;
      }
    }
    div.pw:nth-child(2) {
      align-items: stretch;
      display: flex;
      padding-top: 0;
    }
    div.card {
      width: 47.5%;
      margin-right: 5%;
      margin-bottom: 5%;
      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(5) {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (min-width: 48rem) {
    background-position: 50% 100%;
    background-size: 120%;
    div.card {
      width: 30%;
      margin-right: 5%;
      padding: 50px 50px 80px 50px;
      a[role='button'] {
        bottom: 50px;
        left: 50px;
      }
      &:nth-child(2n) {
        margin-right: 5%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(5),
      &:nth-child(4) {
        margin-bottom: 0;
      }
      &:hover {
        transform: scale(1.075);
      }
    }
  }

  @media screen and (min-width: 64rem) {
    div.card {
      h3 {
        text-align: left;
        padding-top: 120px;
        background-size: auto 100px;
        font-size: 1.75rem;
      }
      p {
        font-size: 1rem;
        line-height: 1.5rem;
      }
      a[role='button'] {
        font-size: 1.125rem;
      }
    }
  }

  @media screen and (min-width: 84rem) {
    background-position: 50% 100%;
    background-size: 110%;
  }

  @media screen and (min-width: 96rem) {
    background-position: 60% 100%;
    background-size: 90%;
  }
}

/* section.growth */

section.peoplelikeyou {
  background: #ffffff;
  padding-bottom: 5%;
  p {
    line-height: 1.5;
    font-size: 1.375rem;
    text-align: center;
    max-width: 287px;
    margin: 0 auto 1rem;
  }
  h3 {
    padding-top: 120px;
    margin-bottom: 0.5rem;
    background: url(../../assets/Search.svg) no-repeat center top;
    background-size: 110px;
    font-size: 28px;
    line-height: 35px;
    text-align: center;
  }

  div.pw:nth-child(2),
  div.pw:nth-child(3) {
    padding-top: 0;
  }

  div.pw:nth-child(2) {
    div.iconic:nth-child(2) h3 {
      background-image: url(../../assets/Hire.svg);
    }
    div.iconic:nth-child(3) h3 {
      background-image: url(../../assets/Results.svg);
    }
  }

  @media screen and (min-width: 40rem) {
    div.pw:first-child h2 {
      margin-top: 1rem;
    }
    div.pw:nth-child(2) {
      margin-bottom: 0;
      div.iconic {
        width: 30%;
        margin-right: 5%;
        margin-bottom: 0;
      }
      div.iconic:last-child {
        margin-right: 0;
      }
    }
    div.pw:last-child {
      margin-bottom: 2.5%;
    }
  }

  @media screen and (min-width: 64rem) {
    padding-bottom: 0;
    h2 {
      max-width: 700px;
      margin: 1rem auto 0;
    }
  }

  @media screen and (min-width: 72rem) {
    div.pw:nth-child(2) {
      max-width: 1100px;
    }
  }
}

section.testimonials {
  background: #fff url(../../assets/testimonialsbg.png) no-repeat center right;
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 35%;
  margin-bottom: -20%;
  div.pw:nth-child(2) {
    max-width: 1100px;
  }
  div.Testimonial {
    margin-bottom: 1rem;
    margin-top: 3rem;
    padding: 1.5rem;
    padding-top: 3rem;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 2px 11px rgba(195, 208, 216, 0.5);
    text-align: center;
  }

  .Card__copy {
    margin: 0;
    position: relative;
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1.6;
    &:before {
      content: '';
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      left: 50%;
      margin-left: -35px;
      top: -80px;
      background: url(../../assets/Chat.svg) no-repeat center center;
      background-size: 70px;
    }
  }
  .Card__attribution {
    margin-top: 1rem;
    font-weight: 700;
    font-size: 1.125rem;
    text-align: left;
  }

  @media screen and (min-width: 40rem) {
    padding-bottom: 15%;
    margin-bottom: -10%;
    div.pw:first-child {
      margin-bottom: 0;
      h2 {
        max-width: 390px;
        margin: 1rem auto;
        margin-top: 4rem;
      }
      h4 {
        max-width: 500px;
        margin: 0 auto !important;
      }
    }
    div.pw:nth-child(2) {
      margin-bottom: 0;
    }
    a.largeBtn {
      max-width: 228px;
      margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: 48rem) {
    padding-bottom: 8%;
    margin-bottom: 0%;
    div.pw:first-child {
      display: block;
    }
    div.pw:nth-child(2) {
      padding-top: 2%;
    }
  }

  @media screen and (min-width: 64rem) {
    padding-bottom: 4%;
    div.pw:first-child {
      h2 {
        max-width: 780px;
      }
      h4 {
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.6;
      }
    }
    div.pw:nth-child(2) {
      align-items: stretch;
      div[data-st='st-four'] {
        margin-bottom: 0;
        padding: 2.8125rem;
        padding-top: 3rem;
        div.Card__copy {
          margin-bottom: 2rem;
        }
      }
    }
  }

  @media screen and (min-width: 72rem) {
    padding: 3% 0 2%;
    background-size: 100% 100%;
  }
}

/* section.testimonials */

section.freelancer {
  background: #ffffff;
  p {
    line-height: 1.5;
    font-size: 1.375rem;
    a {
      font-weight: 700;
      color: $secondary-text;
    }
  }
  @media screen and (min-width: 40rem) {
    img {
      max-width: 50%;
      margin: 0 auto;
      display: block;
    }
    h2 {
      max-width: 360px;
      margin: 0 auto 1.5rem;
    }
    p {
      max-width: 440px;
      margin: 0 auto;
    }
  }

  @media screen and (min-width: 48rem) {
    div[data-st='st-six']:first-child {
      order: 2;
      margin-right: 0;
    }
    div[data-st='st-six']:last-child {
      order: 1;
      margin-right: 5.6% !important;
      h2 {
        text-align: left;
        margin-left: 0;
      }
      a[role='button'] {
        float: left;
      }
    }
    img {
      max-width: 100%;
    }
  }

  @media screen and (min-width: 64rem) {
    div.pw {
      padding-top: 0;
      max-width: 1000px;
    }
    div[data-st='st-six']:first-child {
      width: 57.5%;
    }
    div[data-st='st-six']:last-child {
      width: 45.5%;
      h2 {
        margin-top: 26%;
        max-width: 420px;
        float: left;
        line-height: 1.4;
      }
      p {
        max-width: 500px;
        display: block;
        clear: both;
        float: left;
      }
    }
  }
}
