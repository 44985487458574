.LocationInput {
    &--heading {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &-toggle {
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);

        @include respond-to(1024px) {
            right: 0;
        }
    }

    &-tag {
        display: inline-block;
        cursor: pointer;
        font-size: 0.75rem;
        color: $black;
        padding: 0.25rem 0.5rem;
        border-radius: 25px;

        &:not(&--selected):hover {
            border-color: black;
        }

        &--selected {
            background: $black;
            color: white;
            font-weight: bold;
            cursor: default;
        }

        & + & {
            margin-left: 0.5rem;
        }
    }

    &--algolia {
        .ap-input-icon {
            display: none;
            // right: 1rem;
            // top: 50%;
            // transform: translateY(-50%);
            // width: auto;
            // margin: 0;
            // padding: 0;
            // display: flex;
            // align-items: center;
            // justify-content: center;
        }
    }
}
