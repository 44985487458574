.Button {
  cursor: pointer;
  padding: 1rem;
  border-radius: 999px;
  border: none;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &--default {
    @extend .Button;
    min-width: 225px;
  }

  &--gold {
    background: linear-gradient(
      225deg,
      rgba(231, 192, 58, 1) 0%,
      rgba(252, 227, 159, 1) 20%,
      rgba(231, 192, 58, 1) 40%,
      rgba(173, 130, 0, 1) 100%
    );
    color: white;
    padding: 1rem 2rem;
    font-family: 'Montserrat', sans;
  }

  &--ghost {
    background: transparent;
    background: #faf9fc;
    border: 2px solid $black;
    padding: 0.75rem 1rem;
    color: $black;
    font-family: 'Montserrat', sans;

    &:hover {
      cursor: pointer;
    }

    &:disabled,
    &.disabled {
      cursor: default;
      opacity: 0.75;
    }
  }

  &--underline {
    background: transparent;
    border: none;
    color: $black;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-decoration: underline;

    &__white {
      color: white;
    }

    &__small {
      font-size: 0.8rem;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  &--plain {
    border: none;
    background: transparent;
    padding: 0;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }

    &__narrow {
      width: auto !important;
    }

    &__close {
      display: flex;
      max-width: 40px !important;
      margin: 0 0.75rem;
    }
  }

  &--form {
    background-color: white;
    border: 1px solid $black;
    text-transform: none;
  }

  &--reload {
    @extend .Button--form;
    border: none;
  }

  &--small {
    padding: 0.25rem 0.5rem;
  }

  &[disabled] {
    opacity: 0.75;
  }

  &--submit {
    background-color: $black;
    color: white;
    font-weight: bold;
  }

  &--clear {
    cursor: pointer;
    position: absolute;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 100%;
    border: none;
    background-color: rgba(#ddddde, 0.5);
    outline: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.15s;
    // background-image: center / contain no-repeat url(../../assets/close.svg);
    &:hover,
    &:focus {
      background-color: #ddddde;
    }

    &:before {
      content: '\2715';
      @include center;
      font-size: 0.65rem;
    }
  }
}
