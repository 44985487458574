/* @tailwind base; */
@tailwind components;
@tailwind utilities;

/* -------------------------- Marketing Made Simple Theme -------------------------- */
:root [data-app='mms'] {
  /* Theme Colors */
  --color-black: #000;
  --color-black-light: rgba(0, 0, 0, 0.9);
  --color-gold: #e7c03a;
  --color-gold-light: #fce39f;
  --color-header-text: #2b2d3a;
  --color-paragraph-text: #181920;
  --color-secondary-text: #13141b;

  /* Menu Logo */
  --image-logo: url(./assets/mms-logo.svg) center right no-repeat;
  --image-text-logo: url(./assets/mms-text-only-logo.svg) center right no-repeat;

  /* Footer Logo */
  --image-footer-logo: url(./assets/cym_logo_dark.svg) center right no-repeat;
}

.App {
  @apply text-black;
}

p,
li,
label {
  @apply text-base tracking-wide text-black;
}

label {
  @apply block mb-1 font-semibold;
}

input,
textarea {
  @apply w-full px-4 py-3 text-base tracking-wide placeholder-gray-400 border-none rounded-sm bg-gray-50 focus:outline-dark;
}

.btn {
  @apply inline-block px-8 py-4 font-bold tracking-wide text-center no-underline uppercase cursor-pointer rounded-btn;
}

.btn--primary {
  @apply text-white bg-black;
}

.btn--primary-ghost {
  @apply text-black transition-all duration-200 border border-black border-solid hover:text-white hover:bg-black;
}

ul {
  @apply leading-6 text-black list-none;
}

.home-section h1 {
  font-size: 44px;
  text-align: center;

  @media (min-width: 1280px) {
    font-size: 80px;
  }
}

.home-section h2 {
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  @media (min-width: 1280px) {
    font-size: 48px;
    line-height: 56px;
  }
}

.home-section h3 {
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;

  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.btn--gold {
  border: solid 1em transparent;
  border-radius: 30px;
  background-image: linear-gradient(
    225deg,
    rgba(231, 192, 58, 1) 0%,
    rgba(252, 227, 159, 1) 20%,
    rgba(231, 192, 58, 1) 40%,
    rgba(173, 130, 0, 1) 100%
  );
  background-origin: border-box;
  background-clip: padding-box, border-box;
}

.gold-text-gradient {
  background: linear-gradient(235deg, #e7c03a 22.18%, #fce39f 29.46%, #e7c03a 36.39%, #ad8200 57.7%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.glass-card {
  padding: 32px;
  border-radius: 48px;
  border: 2px solid rgba(255, 255, 255, 0);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(50px);
}

.glass-card-icon-container {
  display: flex;
  align-self: start;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: linear-gradient(315deg, #ad8200 0%, #e7c03a 50.03%, #fce39f 74.97%, #e7c03a 100%);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.5);
}

.glass-card-heading {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.glass-card-copy {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
}

.glass-card-link {
  display: flex;
  align-items: center;
  padding: 16px;
  flex-direction: row;
  gap: 10px;
  border-radius: 16px;
  border: 2px solid #000;
  align-self: start;
  font-weight: 700;
  text-decoration: none;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: 0.3s;
  background: transparent;
}

.glass-card-link:hover {
  background: black;
  color: white;
}

.glass-card-link:hover path {
  stroke: white;
}

.hiring-step-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 64px;
}

.get-certified-heading {
  text-align: center !important;

  @media (min-width: 1280px) {
    text-align: left !important;
  }
}

.hiring-step-title {
  color: #000;
  text-align: center;

  /* H3 */
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 100% */
}

.hiring-step-copy {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
}

.hiring-gold-border {
  border: 2px solid transparent;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(
      225deg,
      rgba(231, 192, 58, 1) 0%,
      rgba(252, 227, 159, 1) 20%,
      rgba(231, 192, 58, 1) 40%,
      rgba(173, 130, 0, 1) 100%
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  color: black;
  transition: 0.3s;
}

a.hiring-gold-border:hover {
  background: black;
  color: white;
}

.hiring-gold-border-dark {
  border: 2px solid transparent;
  background-image: linear-gradient(black, black),
    linear-gradient(
      225deg,
      rgba(231, 192, 58, 1) 0%,
      rgba(252, 227, 159, 1) 20%,
      rgba(231, 192, 58, 1) 40%,
      rgba(173, 130, 0, 1) 100%
    );
  background-origin: border-box;
  background-clip: padding-box, border-box;
  transition: 0.3s;
}

.hiring-gold-border-dark:hover {
  border: 2px solid transparent;
  background: black;
  transition: 0.3s;
}

.hover-light:hover {
  border: 2px solid transparent;
  background: white;
  transition: 0.3s;
  color: black !important;
}

.hiring-gold-border-dark:focus {
  border: 1px solid white;
}

.small-copy {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  color: #000;
  font-family: Montserrat;
}

.carousel-container {
  position: relative;
  width: 100%;
  height: 100px;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.carousel-slide-content {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
}

.carousel-dots-container {
  display: flex;
  gap: 16px;
  position: absolute;
  bottom: 0;
  margin-bottom: -100px;
  z-index: 20;
}

.carousel-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: background-color 0.3s;
  background-color: #000;
}

.carousel-testimonial {
  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: 1.2px;
  text-align: center;
  max-width: 1000px;
}

.carousel-testimonial-author {
  color: #000;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.8px;
  margin-top: 24px;
}

.get-certified-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: 1280px) {
    width: 550px;
    justify-content: start;
    align-items: start;
  }
}
.rc-tooltip {
  font-family: 'Montserrat', sans;
}

#home-video {
  height: 110vh;
  @media (min-width: 740px) {
    height: 100vh;
  }
}
